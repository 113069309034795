.mound {
  position: fixed;
  width: 38em;
  height: 38em;
  border-radius: 30em;
  bottom: -35em;
  left: 60%;
  transform: translateX(-50%);
}

.mound::before {
  content: "";
  position: fixed;
  background: inherit;
  width: 45em;
  height: 45em;
  border-radius: 45em;
  top: -5em;
  left: -25em;
  z-index: 0;

}


.background {
  /* Matches the h-16 height of the header */
  height: calc(100% - 4rem);

}


.trunk {
  position: absolute;
  bottom: 1em;
    left: 45%;
    width: 15%;
    height: 40%;
  background-color: #663300;
  box-shadow: inset -1.7em 0 0 rgba(0, 0, 0, 0.1);
  border-top-right-radius: 10px;
  cursor: pointer;
  transition: filter 1s, box-shadow 1s;
  transform: rotateZ(353deg)

}
.trunk:hover {
  filter: brightness(150%);
  transition: filter 1s;
}
/* .trunk:before {
  content: "";
  position: absolute;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  top: -6em;
  left: -3.2em;
  transform: rotateZ(-54deg);
  background-color: inherit;
  box-shadow: inset -2.6em 0 0 rgba(0, 0, 0, 0.1);
  height: 10em;
  width: 4em;
}
.trunk:after {
  content: "";
  position: absolute;
  border-bottom-left-radius: 10px;
  top: -11.9em;
  left: -6em;
  transform: rotateZ(4deg);
  background-color: inherit;
  box-shadow: inset -1em 0 0 rgba(0, 0, 0, 0.1);
  height: 10em;
  width: 4em;
} */


.top {
  position: absolute;
  height: 7em;
  border-radius: 90px;
  top: 17em;
  z-index: 1;
  transition: transform 1s;
}










.sun {
  position: fixed;

    /* left: 0; */
    right: -75px;
    bottom: 0;
    /* margin: auto; */
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #FCE570;
    opacity: 0.9;
    box-shadow: 0px 0px 40px 15px #FCE570;

}

.ray_box {
  position: absolute;
  margin: auto;
	top:0px;
	left:0;
	right:0;
	bottom:0;
  width:70px;
  -webkit-animation: ray_anim 120s linear infinite;
  animation: ray_anim 120s linear infinite;
}
.ray {
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%);
		background: linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%);
    margin-left:10px;
    border-radius:80% 80% 0 0;
    position:absolute;
    opacity:0.1;
}

.ray1 {
    height:170px;
    width:30px;
   -webkit-transform: rotate(180deg);
    top:-175px;
    left: 15px;
}
.ray2 {
    height:100px;
    width:8px;
   -webkit-transform: rotate(220deg);
    top:-90px;
    left: 75px;
}
.ray3 {
    height:170px;
    width:50px;
   -webkit-transform: rotate(250deg);
    top:-80px;
    left: 100px;
}
.ray4 {
    height:120px;
    width:14px;
   -webkit-transform: rotate(305deg);
    top:30px;
    left: 100px;
}
.ray5 {
    height:140px;
    width:30px;
   -webkit-transform: rotate(-15deg);
    top:60px;
    left: 40px;
}
.ray6 {
    height:90px;
    width:50px;
   -webkit-transform: rotate(30deg);
    top:60px;
    left: -40px;
}
.ray7 {
    height:180px;
    width:10px;
   -webkit-transform: rotate(70deg);
    top:-35px;
    left: -40px;
}
.ray8 {
    height:120px;
    width:30px;
   -webkit-transform: rotate(100deg);
    top:-45px;
    left:-90px;
}
.ray9 {
    height:80px;
    width:10px;
   -webkit-transform: rotate(120deg);
    top:-65px;
    left:-60px;
}
.ray10 {
    height:190px;
    width:23px;
   -webkit-transform: rotate(150deg);
    top:-185px;
    left: -60px;
}


@-webkit-keyframes ray_anim {
		0% { -webkit-transform: rotate(0deg); transform: rotate(0deg);}
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg);}
}


.meadowgrass1 {
  position: fixed;
  height: 98px;
  width: 300px;
  bottom: -20px;
}

.radialprogress {
  position: relative;
    display: inline-grid;
    height: var(--size);
    width: var(--size);
    place-content: center;
    border-radius: 9999px;
    background-color: transparent;
    vertical-align: middle;
    box-sizing: content-box;
    --value: 0;
    --size: 5rem;
    --thickness: calc(var(--size) / 10);
}